<template>
    <div class="interactive-animation">
        <img
            v-if="placeholderTop"
            class="interactive-animation__image"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            :style="`padding-top: ${placeholderTop}%;`"
            :alt="`${title}`"
            loading="lazy"
        >
        <Vue3Lottie
            :animation-data="parsedAnimationData"
            :width="width"
            :height="height"
            class="interactive-animation__lottie"
            :class="[loaded ? 'loaded' : 'loading', placeholderTop ? 'placeholder-top' : 'placeholder-normal']"
            @on-animation-loaded="loaded = true"
        />

        <div
            v-show="activeTooltip !== null"
            class="interactive-animation__active-tooltip-container"
            :style="`left: ${contentLeft}%; top: ${contentTop}%; max-width: calc(${100 - contentLeft - 1}%);`"
        >
            <div
                class="interactive-animation__active-tooltip"
                @click="activeTooltip = null"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        d="M4.28 3.22a.75.75 0 00-1.06 1.06L8.94 10l-5.72 5.72a.75.75 0 101.06 1.06L10 11.06l5.72 5.72a.75.75 0 101.06-1.06L11.06 10l5.72-5.72a.75.75 0 00-1.06-1.06L10 8.94 4.28 3.22z"
                        clip-rule="evenodd"
                    />
                </svg>
            </div>

            <strong>{{ tooltips[activeTooltip]?.label ?? '' }}</strong>:
            <!-- eslint-disable vue/no-v-html -->
            <span
                v-html="tooltips[activeTooltip]?.description ?? ''"
            ></span>
        </div>

        <span
            v-for="(tooltip, i) in tooltips"
            :key="tooltip.label"
            class="interactive-animation__tooltips"
            :style="`left: ${tooltip.left}%; top: ${tooltip.top}%;`"
            @click="activeTooltip = i"
        >
            {{ tooltip.label }}
        </span>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: '',
    },
    animation: {
        type: [String, Boolean],
        required: false,
        default: '',
    },
    tooltipSet: {
        type: String,
        required: true,
    },
    tooltipContentPosition: {
        type: String,
        required: true,
    },
});

function b64DecodeUnicode(str) {
    try {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    } catch (error) {
        console.error(error);

        return '{}';
    }
}

const parsedAnimationData = JSON.parse(b64DecodeUnicode(props.animation));
const parsedTooltipSet = b64DecodeUnicode(props.tooltipSet || '');
const width = parsedAnimationData.w || null;
const height = parsedAnimationData.h || null;

const contentRegexp = /\s*([\d.]+),\s*(\d+)\s*/g;
const tooltipRegexp = /#([\w\s.]+)\s*\[\s*([\d.]+),\s*([\d.]+)\s*\]\s*(.*)/g;
const activeTooltip = ref(null);
const tooltips = [];
let contentLeft = 0;
let contentTop = 0;

const contentPosition = new RegExp(contentRegexp).exec(props.tooltipContentPosition);
if (contentPosition && contentPosition.length === 3) {
    contentLeft = contentPosition[1];
    contentTop = contentPosition[2];
}

parsedTooltipSet?.split('\n').forEach(line => {
    const matches = new RegExp(tooltipRegexp).exec(line);
    if (matches && matches.length === 5) {
        tooltips.push(
            {
                left: matches[2],
                top: matches[3],
                label: matches[1],
                description: matches[4],
            },
        );
    }
});
const loaded = ref(false);
const placeholderTop = height && width ? (height * 100 / width) : 0;

</script>

<style lang="scss" scoped>
@use "watson-theme-interactive-animation";
</style>
